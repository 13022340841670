<template>
  <div class="info-banner">
    <div class="container">
      <h2 class="info-banner__title">
        Reach millions by getting a world-class presence
      </h2>
      <div
        class="info-banner__desc description"
      >
        All-in-One or Unbundled For All Your Ministry Needs
      </div>
      <div class="info-banner-content">
        <div class="info-banner__img">
          <img
            src="../assets/images/banner.png"
            alt=""
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'InfoBanner',
};
</script>

<style lang="scss">
  @import '../assets/sass/utils/variables';

  .info-banner {
    background: $color-white;
    padding-top: 83px;
    position: relative;

    &__desc {
      margin-top: 9px;
    }

    &-content {
      display: flex;
      justify-content: space-between;
      position: relative;
      margin-top: 100px;
    }

    &__img {
      max-width: 1140px;
      width: 100%;
    }

  }

  @media (max-width: 767px) {

    .info-banner {
      padding-top: 55px;

      &__desc {
        margin-top: 16px;
      }

      &-content {
        margin-top: 25px;
      }

    }
  }

  @media (max-width: 480px) {
    .info-banner {
      &-content {
        margin-left: 0;
        margin-right: 0;
      }

      &__img {
        max-width: 100%;
      }
    }
  }
</style>
