<template>
  <div>
    <!--<transition appear :name="$route.name === 'pricingPage' ? 'fade-start': 'fade-end'" mode="out-in">-->
    <FixedHeader :fixed.sync="isFixed">
      <div
        class="header-block"
        :class="{'header-block_fixed': isFixed}"
      >
        <div class="container container_extend">
          <div class="header-block-content">
            <div class="header-block-left">
              <div class="logo">
                <img
                  src="../assets/images/logo.png"
                  alt=""
                >
              </div>
            </div>
            <div
              v-if="$route.name !== 'vip2'"
              class="header-block-right"
            >
              <div class="offer">
                <button
                  class="btn actions__btn actions__btn_get"
                  @click="scrollToForm()"
                >
                  Schedule a Demo
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </FixedHeader>
    <!--</transition>-->
  </div>
</template>

<script>
import FixedHeader from 'vue-fixed-header';
import Countdown from 'vuejs-countdown';
// import modalsVideo from '@/components/modals/modalsVideo'
// import modalsGetStarted from '@/components/modals/modalsGetStarted'
// import modalsAttendance from '@/components/modals/modalsAttendance'
// import modalsNewProject from '@/components/modals/modalsNewProject'
// import modalsAuth from '@/components/modals/modalsAuth'
// import selectTemplate from '@/components/selectTemplate'
import find from 'lodash/find';

const modalsVideo = () => import('@/components/modals/modalsVideo');
const modalsGetStarted = () => import('@/components/modals/modalsGetStarted');
const modalsAttendance = () => import('@/components/modals/modalsAttendance');
const modalsNewProject = () => import('@/components/modals/modalsNewProject');
const modalsAuth = () => import('@/components/modals/modalsAuth');
const selectTemplate = () => import('@/components/selectTemplate');

export default {
  name: 'HeaderBlock',
  components: {
    FixedHeader,
    Countdown,
    modalsVideo,
    modalsGetStarted,
    modalsAttendance,
    modalsNewProject,
    modalsAuth,
    selectTemplate,
  },
  props: ['showModalVideo'],
  data() {
    return {
      currentSite: '',
      showModalAttendance: false,
      offerDays: '13',
      offerHours: '4',
      offerMinutes: '28',
      offerSeconds: '45',
      showModalAuth: false,
      // pricingObj: {
      //   firstName: '',
      //   lastName: '',
      //   email: '',
      //   organization: '',
      //   type: 'Church',
      //   attendance: 0
      // }
      isFixed: false,
      sites: {
        solution1: {
          key: 'solution1',
          text: 'Website',
          link: '/websites',
          routeName: 'index',
        },
        solution2: {
          key: 'solution2',
          text: 'Mobile App',
          link: '/mobile',
          routeName: 'mobilePage',
        },
        solution3: {
          key: 'solution3',
          text: 'TV App',
          link: '/tv',
          routeName: 'tvPage',
        },
      },
      siteActive: 'solution1',
      setTimeoutId: -1,
    };
  },
  computed: {
    selectSiteSettings() {
      return {
        optionList: this.sites,
        fontFamily: false,
        placeholderText: 'SOLUTIONS',
        selectedKey: '',
        disable: false,
        toTop: false,
        tabindex: 1,
        selectClass: 'select_solution',
        notChange: true,
        inputValueStatic: true,
        readonly: true,
        hoverState: true,
        activeElement: true,
      };
    },
    setSite: {
      get() {
        return this.siteActive;
      },
      set(data) {
        this.siteActive = data;
      },
    },
    showModalNewProject: {
      get() {
        return this.$store.getters.showModalNewProject;
      },
      set(data) {
        this.$store.commit('setShowModalNewProject', data);
      },
    },
    showModalGetStarted: {
      get() {
        return this.$store.getters.showModalGetStarted;
      },
      set(data) {
        this.$store.commit('setShowModalGetStarted', data);
      },
    },
    showModalVideoHeader: {
      get() {
        return this.showModalVideo;
      },
      set(data) {
        this.$emit('update:showModalVideo', data);
      },
    },
  },
  methods: {
    scrollTo(element, to, duration, clear) {
      if (duration === 0) {
        return;
      }
      if (to < 0) to = 0;
      const difference = to - element.scrollY;
      const perTick = difference / duration * 10;
      if (clear) {
        clearTimeout(this.setTimeoutId);
      }
      this.setTimeoutId = setTimeout(() => {
        let newTop = element.scrollY + perTick;
        if (newTop < 0) newTop = 0;
        element.scrollTo({
          top: newTop,
        });
        if (newTop === 0) return false;
        if (element.scrollY === to) return;
        this.scrollTo(element, to, duration - 10);
      }, 10);
    },
    scrollToForm() {
      const anchorBlock = document.getElementById('hubspotFormContainer');
      this.scrollTo(window, anchorBlock.offsetTop, 500, 'clear');
    },
    // keyup: function (e) {
    //   console.log(e)
    //   let escKey = 27
    //   if (e.keyCode === escKey) {
    //     e.preventDefault()
    //     e.stopPropagation()
    //     this.$emit('update:showModalVideo', false)
    //     // return false
    //   }
    // },
    changeSite(key) {
      const el = find(this.sites, (obj) => obj.key === key);
      // setTimeout(() => {
      const linkA = document.createElement('a');
      linkA.setAttribute('target', '_blank');
      linkA.setAttribute('href', el.link);
      linkA.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
      // }, 200)

      // this.$router.push({name: el.link})
      // this.setSite = key
    },
  },
  // beforeMount: function () {
  //   this.$nextTick(() => {
  //     document.addEventListener('keyup', this.keyup)
  //   })
  // },
  // beforeDestroy: function () {
  //   document.removeEventListener('keyup', this.keyup)
  // }
};
</script>

<style lang="scss" scoped>
  @import '../assets/sass/utils/variables';

  .header-block {
    display: flex;
    min-height: 104px;
    align-items: center;
    position: absolute;
    z-index: 1000;
    width: 100%;
    top: 0;
    left: 0;
    transition: all .14s ease;

    &_fixed {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      z-index: 1000;
      background: $color-shark;
      box-shadow: 0 1px 5px 0 rgba(0, 0, 0, .3);
      min-height: 56px;

      .header-block-left, .header-block-right {
        width: auto;
      }

      .offer {
        min-width: auto;
      }

    }

    //.offer {
    //  display: flex;
    //  align-items: center;
    //  position: relative;
    //  color: $color-white;
    //  font-family: $font-global-bold;
    //  line-height: 56px;
    //  font-size: 32px;
    //
    //  a {
    //    text-decoration: none;
    //    margin-left: 5px;
    //    color: $color-viking;
    //  }
    //
    //}

    .actions {
      display: flex;
      align-items: center;
      margin-right: 45px;
      margin-top: 2px;

      &__btn {
        transition: all .14s ease;

        &_get {
          width: auto!important;
          height: 40px;
          background: $color-moody-blue;
          font-family: $font-global-medium, sans-serif;
          font-size: 16px;
          color: $color-white;
          padding: 0 15px;
          white-space: nowrap;
          border: 1px solid transparent;
          transition: opacity .2s ease-in, background .2s ease-in, color .2s ease-in;
          user-select: none;

          &:hover {
            border-color: transparent!important;
            background: $color-white!important;
            color: $color-moody-blue;
            opacity: 1!important;
          }

          &:active {
            background: $color-martinique;
            opacity: 1;
          }

          &:after {
            content: '';
            position: absolute;
            top: 50%;
            left: 50%;
            width: 10px;
            height: 10px;
            background: rgba(255, 255, 255, .5);
            opacity: 0;
            border-radius: 100%;
            transform: scale(1, 1) translate(-50%);
            transform-origin: 50% 50%;
          }

          @keyframes ripple {
            0% {
              transform: scale(0, 0);
              opacity: 1;
            }
            20% {
              transform: scale(25, 25);
              opacity: 1;
            }
            100% {
              opacity: 0;
              transform: scale(40, 40);
            }
          }

          &:focus:not(:active)::after {
            animation: ripple 1.5s ease-out;
          }
        }

        &_try {
          border-color: $color-white;
          color: $color-white;
          width: 116px;

          &:hover {
            background: $color-white;
            color: $color-moody-blue;
          }
        }

        &:last-child {
          margin-left: 16px;
        }
      }
    }

    .logo {
      max-width: 215px!important;
      top: 0!important;
      width: 100%;
      transition: width .14s ease;
      cursor: default;

      img {
        display: block;
      }
    }

    &-content {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &-right {
      display: flex;
      align-items: center;
      position: relative;
    }

  }

  @media (max-width: 1199px) {
    .header-block {
      .logo {
        max-width: 180px;
      }
    }
  }

  @media screen and (max-width: 767px){

    .header-block {
      min-height: 60px;

      .logo {
        max-width: 130px;
      }

      .offer {
        font-size: 20px;
      }
    }
  }

  @media screen and (max-width: 500px){
    .offer {
      font-size: 18px;
    }
  }

  .header-block-link, .header-block .offer {
    display: flex!important;
  }

</style>
