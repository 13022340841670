<template>
  <div class="control-method">
    <div class="container">
      <h2 class="control-method__title">
        {{ title }}
      </h2>
      <div
        v-if="showSubTitle"
        class="control-method__desc description"
      >
        {{ subTitle }}
      </div>
      <div class="control-method-content">
        <div class="control-method__img">
          <img
            id="controlMethodContentImg"
            src="../assets/images/old-vs-new.gif"
            alt=""
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import get from 'lodash/get';

export default {
  name: 'ControlMethod',
  props: ['settings'],
  data() {
    return {};
  },
  computed: {
    title() {
      const title = get(this.settings, 'title', 'Change the way you manage your organization');
      return title;
    },
    subTitle() {
      const subTitle = get(this.settings, 'subTitle', 'Lean Principles. Operational Efficiency. Process Automation. Simple & Secure.');
      return subTitle;
    },
    showSubTitle() {
      const showSubtitle = get(this.settings, 'showSubtitle', true);
      return showSubtitle;
    },
  },
};
</script>

<style lang="scss">
  @import '../assets/sass/utils/variables';

  .control-method {
    background: $color-white;
    padding-bottom: 188px;
    position: relative;

    &__desc {
      margin-top: 9px;
    }

    &-content {
      display: flex;
      justify-content: space-between;
      position: relative;
      margin: 163px -15px 0;
    }

    &__img {
      max-width: 1140px;
      width: 100%;
    }

    /*
    &__vs {
      position: absolute;
      left: 44.8%;
      top: 58%;
      transform: translate(-50%, -50%);
      font-size: 26px;
      text-transform: uppercase;
      font-family: $font-global-bold, sans-serif;
      color: $color-jumbo;
    }

    &-right {
      width: 54%;
      display: flex;
      flex-direction: column;

      .old-way__title {
        position: relative;
        left: 28px;
      }

      .old-way__img {
        position: relative;
        left: 39px;
      }
    }

    &-left {
      width: 46%;
      display: flex;

      .old-way__title {
        position: relative;
        left: -24px;
      }

      .old-way__img {
        position: relative;
        left: -15px;
      }
    }*/
  }

  @media (max-width: 767px) {

    .control-method {
      padding-bottom: 55px;

      &__desc {
        margin-top: 16px;
      }

      &-content {
        margin-top: 25px;
      }

    }
  }

  @media (max-width: 480px) {
    .control-method {
      &-content {
        margin-left: 0;
        margin-right: 0;
      }

      &__img {
        max-width: 100%;
      }
    }
  }
</style>
