<template>
  <div
    id="hubspotFormContainer"
    class="hubspot-form"
  >
    <div class="container">
      <h2 class="hubspot-form__title">
        <span>One Time COVID 19 Discount</span>
        <span>Talk to our product expert!</span>
      </h2>
      <div class="hubspot-form__sub-title description">
        VIP discount for the first 50 churches to sign up. Unbeatable pricing.
      </div>
      <div class="hubspot-form__content">
        <div id="hubspotForm">
          <div
            id="amofroms_main_wrapper"
            class="amoforms__fields__editor-withborders  sidebar_none"
            style="max-width: 509px;"
          >
            <form
              id="amoforms_form"
              class="amoforms-form amoforms-view-form"
              action="https://forms.amocrm.com/queue/add"
              method="POST"
              enctype="multipart/form-data"
              style="height: 670px;"
              @submit.prevent="submitForm"
            >
              <div class="amoforms__fields__container amoforms__fields__container_text">
                <div class="amoforms__fields__container__inner amoforms__fields__container__inner_text  amoforms__fields__container__inner_above ">
                  <div
                    class="amoforms__field__name amoforms__field__name_text"
                    title="Your Name"
                  >
                    <label class="amoforms__field__name-label">
                      <div>Your Name</div>
                    </label>
                    <span class="amoforms__field__required">*</span>
                  </div>

                  <div
                    class="amoforms__field__control amoforms__field__control_text js-amoforms-border-radius js-amoforms-field-height"
                    :class="{'amoforms__field__error': !userName && error}"
                  >
                    <input
                      v-model="userName"
                      type="text"
                      name="fields[name_1]"
                      placeholder="Please enter your name"
                      class="amoforms_placeholder js-form-changes-skip text-input js-amoforms-font js-amoforms-color  amoforms-validate_required"
                    >
                    <div
                      v-if="!userName && error"
                      class="amoforms__field__error_message"
                    >
                      Invalid required field
                    </div>
                  </div>
                </div>
              </div>

              <div class="amoforms__fields__container amoforms__fields__container_multitext">
                <div class="amoforms__fields__container__inner amoforms__fields__container__inner_multitext  amoforms__fields__container__inner_above ">
                  <div
                    class="amoforms__field__name amoforms__field__name_multitext"
                    title="Your Phone Number"
                  >
                    <label class="amoforms__field__name-label">
                      <div>Your Phone Number</div>
                    </label>
                    <span class="amoforms__field__required">*</span>
                  </div>

                  <div
                    class="amoforms__field__control amoforms__field__control_multitext js-amoforms-border-radius js-amoforms-field-height"
                    :class="{'amoforms__field__error': !userPhone && error}"
                  >
                    <input
                      v-model="userPhone"
                      type="tel"
                      class="amoforms_placeholder js-form-changes-skip text-input js-amoforms-font js-amoforms-color  amoforms-validate_required amoforms-validate_phone"
                      name="fields[172599_1][105831]"
                      placeholder="212-111-0000"
                    >
                    <div
                      v-if="!userPhone && error"
                      class="amoforms__field__error_message"
                    >
                      Invalid required field
                    </div>
                  </div>
                </div>
              </div>

              <div class="amoforms__fields__container amoforms__fields__container_multitext">
                <div class="amoforms__fields__container__inner amoforms__fields__container__inner_multitext  amoforms__fields__container__inner_above ">
                  <div
                    class="amoforms__field__name amoforms__field__name_multitext"
                    title="Your Email"
                  >
                    <label class="amoforms__field__name-label">
                      <div>Your Email</div>
                    </label>
                    <span class="amoforms__field__required">*</span>
                  </div>

                  <div
                    class="amoforms__field__control amoforms__field__control_multitext js-amoforms-border-radius js-amoforms-field-height"
                    :class="{'amoforms__field__error': !userEmail && error}"
                  >
                    <input
                      v-model="userEmail"
                      type="email"
                      class="amoforms_placeholder js-form-changes-skip text-input js-amoforms-font js-amoforms-color  amoforms-validate_required amoforms-validate_email"
                      name="fields[172601_1][105843]"
                      placeholder="mail@amocrm.com"
                    >
                    <div
                      v-if="!userEmail && error"
                      class="amoforms__field__error_message"
                    >
                      Invalid required field
                    </div>
                  </div>
                </div>
              </div>

              <div class="amoforms__fields__container amoforms__fields__container_text">
                <div class="amoforms__fields__container__inner amoforms__fields__container__inner_text  amoforms__fields__container__inner_above ">
                  <div
                    class="amoforms__field__name amoforms__field__name_text"
                    title="Your Organization Name"
                  >
                    <label class="amoforms__field__name-label">
                      <div>Your Organization Name</div>
                    </label>
                    <span class="amoforms__field__required">*</span>
                  </div>

                  <div
                    class="amoforms__field__control amoforms__field__control_text js-amoforms-border-radius js-amoforms-field-height"
                    :class="{'amoforms__field__error': !userOrganization && error}"
                  >
                    <input
                      v-model="userOrganization"
                      type="text"
                      name="fields[name_3]"
                      placeholder=""
                      class="amoforms_placeholder js-form-changes-skip text-input js-amoforms-font js-amoforms-color  amoforms-validate_required"
                    >
                    <div
                      v-if="!userOrganization && error"
                      class="amoforms__field__error_message"
                    >
                      Invalid required field
                    </div>
                  </div>
                </div>
              </div>

              <div class="amoforms__fields__container amoforms__fields__container_url">
                <div class="amoforms__fields__container__inner amoforms__fields__container__inner_url  amoforms__fields__container__inner_above ">
                  <div
                    class="amoforms__field__name amoforms__field__name_url"
                    title="Website"
                  >
                    <label class="amoforms__field__name-label">
                      <div>Website</div>
                    </label>
                    <span
                      class="amoforms__field__required"
                      style="display: none"
                    >*</span>
                  </div>

                  <div class="amoforms__field__control amoforms__field__control_url js-amoforms-border-radius js-amoforms-field-height">
                    <input
                      v-model="userWebsite"
                      type="url"
                      name="fields[172603_3]"
                      class="amoforms_placeholder js-form-changes-skip text-input js-amoforms-font js-amoforms-color "
                      placeholder=""
                    >
                  </div>
                </div>
              </div>

              <div class="amoforms__fields__submit">
                <div class="amoforms__submit-button__flex amoforms__submit-button__flex_left">
                  <button
                    id="button_submit"
                    class="amoforms__submit-button amoforms__submit-button_rounded  text-input js-form-changes-skip js-amoforms-font js-amoforms-field-height"
                    :class="{'amoforms__submit_button_shake': disabledBtn && error}"
                    type="submit"
                    :disabled="disabledBtn"
                    style="color: #FFFFFF;
                    background-color: #52c7df;
                    border-radius: 53px;"
                  >
                    <span
                      class="amoforms__spinner-icon"
                      :class="{'show': disabledBtn && loader}"
                    ></span>
                    <span
                      class="amoforms__submit-button-text"
                      :class="{'hide': disabledBtn && loader}"
                    >SUBMIT</span>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import amoCrmApi from '@/api/amoCrmApi';

export default {
  name: 'HubspotFormNine',
  props: ['vip2'],
  data() {
    return {
      userName: '',
      userPhone: '',
      userEmail: '',
      userOrganization: '',
      userWebsite: '',
      error: false,
      disabledBtn: false,
      loader: false,
    };
  },
  methods: {
    submitForm() {
      if (this.userName && this.userPhone && this.userEmail && this.userOrganization) {
        this.disabledBtn = true;
        this.error = false;
        const data = {
          fields: [
            { name: 'Your Name', value: this.userName, position: 1 },
            { name: 'Your Phone Number', value: this.userPhone, position: 2 },
            { name: 'Email', value: this.userEmail, position: 3 },
            { name: 'Your Organization Name', value: this.userOrganization, position: 4 },
            { name: 'Website', value: this.userWebsite, position: 5 },
          ],
        };
        this.loader = true;
        amoCrmApi.sendLeadsToAmoSrm({ data, id: 9 }).then(({ data }) => {
          // console.log(data);
        }).catch((err) => {
          console.error('Error:', err);
        }).finally(() => {
          this.disabledBtn = false;
          this.error = false;
          this.loader = false;
          if (this.vip2) {
            window.location.href = 'https://calendly.com/impactfactors/groupinvite?month=2022-02';
          } else {
            window.location.href = 'https://calendly.com/impactfactorsvip';
          }
        });
      } else {
        this.error = true;
        this.disabledBtn = true;
        setTimeout(() => {
          this.disabledBtn = false;
        }, 2000);
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import '../assets/sass/utils/variables';

.hubspot-form {
  padding: 100px 0 50px;
  background: $color-white;
  position: relative;
  background: rgba($color-black, 0.8);

  @media (max-width: 767px) {
    padding: 50px 0 0;
  }

  &__title {
    margin-bottom: 50px;
    color: $color-white;

    @media (max-width: 767px) {
      margin-bottom: 20px;
    }

    span {
      display: block;
    }
  }

  &__sub-title {
    margin-bottom: 20px;
    color: $color-chartreuse;
  }

  &__content {
    max-width: 430px;
    margin: 0 auto;
  }
}

#amofroms_main_wrapper {
  background: #fff;
  padding: 0;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
  max-width: 509px;
  width: calc(100% - 10px);
  margin: 5px 5px 0;
  position: relative;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}
#amofroms_main_wrapper {
  color: #2e3640;
  box-sizing: border-box;
  border: 1px solid #D8D9DB;
  background: #FFFFFF;
}

* {
  -webkit-font-smoothing: antialiased;
}
.amoforms-form {
  padding: 43px 36px 25px 36px;
  background-color: inherit;
  background-image: inherit;
  background-size: inherit;
  background-repeat: inherit;
  background-position: inherit;
  box-sizing: border-box;
}

#amofroms_main_wrapper.sidebar_none .amoforms-form {
  border-radius: inherit;
}
.amoforms__fields__container {
  width: 100%;
  position: relative;
  border: 1px solid transparent;
  box-sizing: border-box;
  margin-bottom: 18px;
}
.amoforms__fields__editor-withborders .amoforms__fields__container {
  box-sizing: border-box;
}

.amoforms__fields__container * {
  box-sizing: border-box;
}
.amoforms__fields__container__inner {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}
.amoforms__fields__container__inner_above, .amoforms__fields__container__inner_inside {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  -ms-grid-row-align: stretch;
  align-items: stretch;
  position: relative;
}
.amoforms__fields__container__inner_above {
  margin-top: -3px;
  margin-bottom: 2px;
}

.amoforms__fields__container * {
  box-sizing: border-box;
}

.amoforms__field__name {
  margin-right: 12px;
  /* padding-top: 2px; */
  position: relative;
  text-overflow: ellipsis;
  overflow: hidden;
  display: flex;
}

.amoforms__field__name {
  font-size: 18px;
  width: 32%;
}

.amoforms__fields__container__inner_above .amoforms__field__name,
.amoforms__fields__container__inner_inside .amoforms__field__name,
.amoforms__fields__container__inner_terms .amoforms__field__name {
  width: 100%;
  margin-right: 0;
  margin-bottom: 7px;
  margin-top: 0;
  padding-top: 0;
}

.amoforms__field__name label {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  line-height: normal;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 120px;
  padding-right: 2px;
}
.amoforms__fields__container__inner_above .amoforms__field__name label,
.amoforms__fields__container__inner_inside .amoforms__field__name label {
  max-width: none;
  line-height: normal;
}

.amoforms__field__name label div {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.amoforms__fields__container .amoforms__field__required {
  color: red;
  display: inline-block;
  font-size: 15px;
  line-height: 1;
}

.amoforms__field__control_birthday,
.amoforms__field__control_date,
.amoforms__field__control_date_time,
.amoforms__field__control_multitext,
.amoforms__field__control_numeric,
.amoforms__field__control_smart_address_input,
.amoforms__field__control_legal_entity_input,
.amoforms__field__control_text, .amoforms__field__control_url,
.amoforms__field__control_file {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border: 1px solid #d8d9db;
  border-radius: 3px;
  padding: 0 17px;
  height: 53px;
  max-width: none;
}
.amoforms__field__control {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  position: relative;
  box-sizing: border-box;
}
.js-amoforms-field-height {
  height: 53px !important;
  min-height: 53px !important;
}
.js-amoforms-border-radius {
  background-color: #F9F9F9;
  border-color: #D8D9DB;
  border-radius: 0px;
}
.amoforms__fields__container__inner_above .amoforms__field__control,
.amoforms__fields__container__inner_inside .amoforms__field__control,
.amoforms__fields__container__inner .amoforms__field__control_terms {
  -webkit-box-flex: 0;
  -webkit-flex: none;
  -ms-flex: none;
  flex: none;
  max-width: none;
  font-size: 0;
  line-height: 0;
}
.amoforms__field__control.amoforms__field__error,
.amoforms__field__control_smart_address.amoforms__field__error .amoforms-input-select,
.amoforms__field__control.amoforms__field__error .amoforms__field__control_smart_address_input,
.amoforms__field__control.amoforms__field__error .amoforms__field__control_legal_entity_input {
  border-color: #FF6666;
  border-right: 6px solid #FF6666;
}

input, textarea, [contenteditable] {
  -webkit-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
  outline: none;
  font-family: inherit;
}
.amoforms__field__control_birthday .text-input,
.amoforms__field__control_date .text-input,
.amoforms__field__control_date_time .text-input,
.amoforms__field__control_multitext .text-input,
.amoforms__field__control_numeric .text-input,
.amoforms__field__control_smart_address_input .text-input,
.amoforms__field__control_legal_entity_input .text-input,
.amoforms__field__control_text .text-input,
.amoforms__field__control_url .text-input {
  background-color: transparent;
  border: none;
  text-overflow: ellipsis;
  border-radius: 0;
  border-bottom: 1px solid transparent;
  width: 100%;
  height: auto;
  padding: 0px 1px 0;
  font-size: 15px;
}

.amoforms__field__control_birthday .text-input,
.amoforms__field__control_date .text-input,
.amoforms__field__control_date_time .text-input,
.amoforms__field__control_multitext .text-input,
.amoforms__field__control_numeric .text-input,
.amoforms__field__control_smart_address_input .text-input,
.amoforms__field__control_legal_entity_input .text-input,
.amoforms__field__control_text .text-input,
.amoforms__field__control_widget .text-input,
.amoforms__field__control_url .text-input {
  font-size: 18px;
}

.amoforms__field__control .amoforms__field__error_message {
  display: block;
  position: absolute;
  text-align: right;
  bottom: 3px;
  right: 4px;
  width: 95%;
  height: 11px;
  color: red;
  font-size: 11px;
  line-height: 11px;
  pointer-events: none;
}

/* btn */
.amoforms__fields__submit {
  padding: 6px 0;
  border: 1px solid transparent;
  margin-top: -10px;
}
.amoforms__fields__editor-withborders .amoforms__fields__submit {
  padding: 18px 0;
}

.amoforms__submit-button__flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: 0 -5px -10px;
}
.amoforms__submit-button__flex_left {
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.amoforms__fields__submit .amoforms__submit-button {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
  cursor: pointer;
  font-size: 18px;
  font-weight: 500;
  text-overflow: ellipsis;
  background-color: transparent;
  overflow: hidden;
  white-space: nowrap;
  max-width: 405px;
  justify-content: center;
  height: 52px;
  padding: 0 33px;
  border: 0;
  outline: none;
}
.amoforms__submit-button__flex .amoforms__submit-button {
  max-height: 52px;
  min-height: 52px;
  max-width: 100%;
  min-width: -webkit-fit-content;
  min-width: -moz-fit-content;
  min-width: fit-content;
  padding: 0 30px;
  margin: 0 5px 10px;
  box-sizing: content-box;
}

.amoforms__spinner-icon {
  display: none;
  width: 16px;
  height: 16px;
  border: solid 2px transparent;
  border-radius: 100%;
  -webkit-animation: nprogress-spinner 900ms linear infinite;
  animation: nprogress-spinner 900ms linear infinite;
  border-top-color: #fff;
  border-left-color: #fff;
}
@keyframes nprogress-spinner {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.amoforms__submit_button_shake {
  animation-fill-mode: both;
  animation-duration: 0.3s;
  animation-name: shake;
  cursor: default !important;
}

.show {
  display: block;
}
.hide {
  display: none;
}
</style>

<style lang="scss">
.test {
  background-color: red   ;
}
</style>
