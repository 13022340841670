<template>
  <div
    v-if="$route.name !== 'pricingPage'"
    class="footer-block"
  >
    <div class="container">
      <div class="footer-block-content">
        <div class="footer-block-center">
          <div
            class="footer-block-center-content"
          >
            <span class="footer-block__rights">
              Impact Factors, LLC &copy; <span>{{ getYear() }}</span>. All Rights Reserved.
            </span>
            <a
              class="footer-block__privacy"
              @click.prevent="showModalPrivacyPolicy = true"
            >
              Privacy Policy
            </a>
          </div>
        </div>
      </div>
    </div>
    <transition
      name="fade-block-wait"
      appear
      mode="out-in"
    >
      <modals-privacy-policy
        v-if="showModalPrivacyPolicy"
      >
      </modals-privacy-policy>
    </transition>
  </div>
</template>

<script>
const modalsPrivacyPolicy = () => import('@/components/modals/modalsPrivacyPolicy');

export default {
  name: 'FooterBlockVip',
  components: {
    modalsPrivacyPolicy,
  },
  data() {
    return {
    };
  },
  computed: {
    showModalPrivacyPolicy: {
      get() {
        return this.$store.getters.showModalPrivacyPolicy;
      },
      set(data) {
        this.$store.commit('setShowModalPrivacyPolicy', data);
      },
    },
  },
  methods: {
    getYear() {
      const today = new Date();
      const year = today.getFullYear();
      return year;
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '../assets/sass/utils/variables';
  .footer-block {
    display: flex;
    align-items: center;
    background: $color-shark;
    padding: 25px 0 19px;
    position: relative;

    &__privacy {
      color: $color-white;
      line-height: 18px;
      font-size: 14px;
      text-decoration: none;
      margin-left: 8px;
      transition: opacity .3s ease;
      cursor: pointer;

      &:hover, &:focus {
        opacity: .6;
      }
    }

    &-content {
      display: flex;
      justify-content: center;
    }

    &-center {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      line-height: 18px;
      font-size: 14px;
      color: $color-jumbo;

      &-content {
        display: flex;

        &_top {
          margin-top: 12px;
        }
      }
    }

  }

  @media (max-width: 1199px) {
    .footer-block {

      &__rights {
        text-align: center;
      }

     &-content {
       align-items: center;
     }

     &-center {
       width: 100%;
     }
    }
  }

  @media (max-width: 480px) {
    .footer-block {

      &__privacy {
        margin-left: 0;
      }

      &-content {
        flex-direction: column;
        margin: 0;
      }

      &-left {
        display: none;
      }

      &-center {
        width: 100%;

        &-content {
          text-align: center;
          flex-direction: column;
        }
      }

    }
  }
</style>

<style lang="scss">
  @import '../assets/sass/utils/variables';

  .tooltip {
    display: block !important;
    z-index: 10000;

    .tooltip-inner {
      //background-color: $color-abbey;
      background-color: $color-jumbo;
      border-radius: 3px;
      font: 12px/1.2 $font-global;
      color: $color-white;
      padding: 7px 15px;
      box-shadow: none;
      max-width: 270px;
      pointer-events: none;
    }

    .tooltip-arrow {
      width: 0;
      height: 0;
      border-style: solid;
      position: absolute;
      margin: 5px;
      //border-color: $color-abbey;
      border-color: $color-jumbo;
    }
  }

  .tooltip[x-placement^="top"] {
    margin-bottom: 5px;
  }

  .tooltip[x-placement^="top"] .tooltip-arrow {
    border-width: 5px 5px 0 5px;
    border-left-color: transparent !important;
    border-right-color: transparent !important;
    border-bottom-color: transparent !important;
    bottom: -5px;
    left: calc(50% - 5px);
    margin-top: 0;
    margin-bottom: 0;
  }

  .tooltip[x-placement^="bottom"] {
    margin-top: 5px;
  }

  .tooltip[x-placement^="bottom"] .tooltip-arrow {
    border-width: 0 5px 5px 5px;
    border-left-color: transparent !important;
    border-right-color: transparent !important;
    border-top-color: transparent !important;
    top: -5px;
    left: calc(50% - 5px);
    margin-top: 0;
    margin-bottom: 0;
  }

  .tooltip[x-placement^="right"] {
    margin-left: 5px;
  }

  .tooltip[x-placement^="right"] .tooltip-arrow {
    border-width: 5px 5px 5px 0;
    border-left-color: transparent !important;
    border-top-color: transparent !important;
    border-bottom-color: transparent !important;
    left: -5px;
    top: calc(50% - 5px);
    margin-left: 0;
    margin-right: 0;
  }

  .tooltip[x-placement^="left"] {
    margin-right: 5px;
  }

  .tooltip[x-placement^="left"] .tooltip-arrow {
    border-width: 5px 0 5px 5px;
    border-top-color: transparent !important;
    border-right-color: transparent !important;
    border-bottom-color: transparent !important;
    right: -5px;
    top: calc(50% - 5px);
    margin-left: 0;
    margin-right: 0;
  }

  .tooltip[aria-hidden='true'] {
    visibility: hidden;
    opacity: 0;
    transition: opacity .15s, visibility .15s;
  }

  .tooltip[aria-hidden='false'] {
    visibility: visible;
    opacity: 1;
    transition: opacity .15s;
  }

</style>
