<template>
  <div class="powering-ministries-vip">
    <div class="container">
      <div class="powering-ministries-vip-content">
        <h3 class="powering-ministries-vip__title">
          Trusted by large and small churches and ministries
        </h3>
        <div class="powering-ministries-vip-list">
          <div
            v-for="(item, index) in poweringMinistriesItem"
            :key="index"
            class="powering-ministries-vip-item"
            :class="'powering-ministries-vip-item_' + index"
          >
            <a
              href=""
              @click.prevent=""
            >
              <img
                :src="require('../assets/images/clients-logo/' + item.img + '.svg')"
                alt=""
              >
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PoweringMinistriesVip',
  data() {
    return {
      poweringMinistriesItem: [
        {
          img: 'lwl',
        },
        {
          img: 'kcm',
        },
        {
          img: 'cdm',
        },
        {
          img: 'nbcc',
        },
        {
          img: 'wcm',
        },
        {
          img: 'lvch',
        },
      ],
    };
  },
  methods: {
  },
};
</script>

<style lang="scss">
  @import '../assets/sass/utils/variables';

  .powering-ministries-vip {
    background: $color-white;
    position: relative;
    margin-bottom: 83px;

    &-list {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 0 -15px;
    }

    &-item {
      filter: brightness(1);
      transition: filter .2s ease-in;
      margin: 0 15px;
      a {
        cursor: default;
      }

      &:last-child{
        margin-right: 0;
       }

      &:hover {
        filter: brightness(.5);
      }
    }

    &__title {
      margin-bottom: 49px;
      text-align: center;
      font-size: 24px;
      line-height: 33px;
      font-family: $font-global, sans-serif;
    }
  }

  @media (max-width: 1199px) {
    .powering-ministries-vip {
      &-list {
        flex-wrap: wrap;
        justify-content: center;
      }
    }
  }
  @media (max-width: 992px) {
    .powering-ministries-vip {
      &-item {
        text-align: center;
        width: calc(25% - 30px);

        &:nth-child(-n+3){
          width: calc(33.3333% - 30px);
        }
      }
    }
  }
  @media (max-width: 767px) {
    .powering-ministries-vip {
      &__title {
        text-align: center;
      }

      &-list {
        flex-wrap: wrap;
      }
    }
  }

  @media (max-width: 480px) {
    .powering-ministries-vip {
      &-item {
        &_0 {
          width: 14% !important;
        }
      }
    }
  }
</style>
